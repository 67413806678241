
export const ViewFileInformation = ({
  data
}: {
  data: any
}) => {
  return (
    <div>
        <div
          className="h-96  items-center justify-between mt-12 overflow-y-scroll "
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "#3b82f6 #f1f1f1",
          }}
        >
          <div>
            <pre className="bg-gray-100 p-4 rounded-lg overflow-x-auto whitespace-pre-wrap">
              {data ? JSON.stringify(data, null, 2) : "Loading..."}
              </pre>
          </div>
        </div>
      
    </div>
  );
};

export default ViewFileInformation;
