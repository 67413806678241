import { IconCloudUpload, IconFileTypePdf, IconX } from "@tabler/icons-react";
import React, { useState, ChangeEvent, DragEvent } from "react";
import axios from "axios";
import ViewFileInformation from "./ViewFileInformation";
import Data from "./data.json";

const FileUpload: React.FC = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [dragging, setDragging] = useState(false);
  const [fileName, setFileName] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  // const [formData, setFormData] = useState<FormData | null>(null);
  const [data, setData] = useState('');


  const handleDragOver = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (): void => {
    setDragging(false);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setDragging(false);
    if (e.dataTransfer.files.length > 0) {
      handleFileUpload(e.dataTransfer.files[0]);
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.type === "application/pdf") {
        handleFileUpload(file);
      } else {
        setErrorMessage("Invalid file type. Please upload a PDF file.");
      }
    }
  };

  const handleFileUpload = (file: File): void => {
    if (file.type !== "application/pdf") {
      setErrorMessage("Invalid file type. Please upload a PDF file.");
      return;
    }
    setErrorMessage(null); // Clear error message
    setFileName(file.name);
    const formData = new FormData();
    formData.append("file", file);
    const response = axios.post(`${apiUrl}/app/extract-risks`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      console.log(response);
      setData(response.data.data)
    })
    .catch(function (error) {
      console.log(error.response?.data.detail);
    });
  console.log("response", response);
  };

  const handleCancel = (): void => {
    setFileName(null);
  };

  return (
    <div>
      <div className="flex flex-col items-center justify-center mt-10">
        <div className="flex flex-col items-center">
          <span className="text-4xl font-semibold">Broadridge Analysis</span>
          <span className="text-md font-medium mt-8">Upload a PDF file</span>
        </div>

        <div className="flex items-center justify-center w-full">
          <div
            className={`w-full max-w-2xl  ${
              dragging ? "border-blue-500" : "border-gray-300"
            }`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <input
              type="file"
              id="file-upload"
              className="hidden"
              onChange={handleFileChange}
            />
            <label
              htmlFor="file-upload"
              className="flex flex-col items-center justify-center h-full cursor-pointer"
            >
              <div className="relative flex items-center justify-between p-2 w-full h-24 border rounded-lg bg-sky-50">
                <div className="flex items-center">
                  <IconCloudUpload
                    stroke={2}
                    color="grey"
                    // color="#3b82f6"
                    className="h-14 w-14"
                  />
                  <div className="ml-4 flex flex-col">
                    <span className=" mt-6 font-medium ">
                      Drag & drop file here.
                    </span>
                    <span className="text-gray-500 ">
                      limit 200MB per file .PDF
                    </span>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() =>
                    document.getElementById("file-upload")?.click()
                  }
                  className="mt-6 w-36 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                  disabled={fileName ? true : false}
              >
                  Browse Files
                </button>
              </div>
              {errorMessage && (
                <p className="mt-4 text-red-500">{errorMessage}</p>
              )}
            </label>
          </div>
        </div>
      </div>

      {fileName && (
        <div className="flex  items-center justify-center h-full w-full p-4">
          <div className="relative flex items-center justify-between p-2  w-full max-w-2xl h-24 border rounded-lg bg-sky-50">
            <div className="flex items-center">
              <IconFileTypePdf stroke={1} className="h-10 w-10" />
              <div className="ml-4 flex flex-col">
                <p className="text-gray-700">{fileName}</p>
                <h2 className="text-gray-500 text-sm">15MB</h2>
              </div>
            </div>
            <IconX
              stroke={1}
              className="text-gray-600 h-4 w-4 absolute top-2 right-2 cursor-pointer"
              onClick={handleCancel}
            />
          </div>
        </div>
      )}

      {fileName && <ViewFileInformation data={data} />}
    </div>
  );
};

export default FileUpload;
