import FileUpload from "./FileUpload";
import Header from "./Header";

function DashboardPage() {
  return (
    <div className="transition-colors duration-300">
      <Header />
      <div className="p-6">
        <FileUpload />
      </div>
    </div>
  );
}

export default DashboardPage;
