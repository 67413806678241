import React, { useState, useEffect, useRef } from "react";
import profilePic from "./../../assets/profilePic.jpg";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/login");

    // Close the dropdown after logging out
    setIsDropdownOpen(false);
    document.body.style.backgroundColor = "";
    const header = document.getElementById("page-header");
    if (header) {
      header.style.backgroundColor = "";
    }
  };

 
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <header className="border-2 text-white p-2">
      <div className="container mx-auto flex items-center justify-between">
        <div className="text-lg font-semibold"></div>
        <div className="flex items-center space-x-4">
          <div className="relative">
            <img
              src={profilePic}
              alt="Profile"
              className="w-14 h-14 rounded-full cursor-pointer"
              onClick={toggleDropdown}
            />

            {isDropdownOpen && (
              <div
                ref={dropdownRef}
                className="absolute right-0 mt-2 w-48 bg-white text-black rounded shadow-lg z-10"
              >
                <button
                  className="w-full text-left px-4 py-2 hover:bg-gray-200"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
